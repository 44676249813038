import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import ProductSettings from "../products/ProductSettings";
import useProducts from "../products/useProducts";
import NewProduct from "../products/NewProduct";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import ProductRecommendationSettings from "../product_recommendations/ProductRecommendationSettings";
import NewProductRecommendation from "../product_recommendations/NewProductRecommendation";
import Tooltip from "../shared/Tooltip";
import variantCustomFieldsArray from "../../utils/variantCustomFieldsArray";

const ElementSettingsProductFeed = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const { Products } = useProducts();

  const [shopifyGraphqlLoading, setShopifyGraphqlLoading] = useState(false);

  let website = contextData.objects.website;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  const productsList = Object.values(variant.products).filter((p) => p.element_id == element.id && p.toBeDeleted !== true);

  const [productsDropdown, setProductsDropdown] = useState([
    {
      value: "",
      text: "Select a product",
    },
  ]);
  const [handlesDropdown, setHandlesDropdown] = useState([
    {
      value: "",
      text: "Select a product",
    },
  ]);

  const [collectionsDropdown, setCollectionsDropdown] = useState([
    {
      value: "",
      text: "Select collection",
    },
  ]);

  const [productVariantsDropdown, setProductVariantsDropdown] = useState([
    {
      value: "",
      text: "Select product variant",
    },
  ]);

  const shopifyIntegration = Object.values(website.integrations).filter(
    (i) => i.integration_type == "Shopify" && i.status == true
  )[0];

  const product_recommendations = Object.values(
    variant.product_recommendations
  ).filter(
    (product_recommendation) =>
      product_recommendation.element_id == element.id &&
      product_recommendation.toBeDeleted !== true
  );

  useEffect(() => {
    if (["Shopify"].includes(element.options["products-source"])) {
      if (shopifyIntegration) {
        let tempProductsDropdown = [];
        let tempHandlesDropdown = [];
        let tempCollectionsDropdown = [];

        const defaultSelect = { value: "", text: "Select a product", disabled: true }
        tempProductsDropdown.push(defaultSelect)
        tempHandlesDropdown.push(defaultSelect)


        if (shopifyIntegration?.api_data["products"]) {
          shopifyIntegration?.api_data["products"].forEach((shopifyProduct) => {
            tempProductsDropdown.push({
              value: shopifyProduct["product_id"] + "",
              text: shopifyProduct["name"],
            });
  
            tempHandlesDropdown.push({
              value: shopifyProduct["id"] + "",
              text: shopifyProduct["name"],
            });
          });
        }

        if (shopifyIntegration?.api_data["collections"]) {
          shopifyIntegration?.api_data["collections"].forEach(
            (shopifyCollection) => {
              tempCollectionsDropdown.push({
                value: shopifyCollection["id"],
                text: shopifyCollection["name"],
              });
            }
          );
        }

        setProductsDropdown(tempProductsDropdown);
        setHandlesDropdown(tempHandlesDropdown);
        setCollectionsDropdown(tempCollectionsDropdown);

        if (element.options["shopify-products-selected"]) {
          updateShopifyProductVariantsDropdown();
        }
      }
    }

    if (["ShopifyGraphql"].includes(element.options["products-source"])) {
      fetchShopifyGraphql();
    }
  }, [element.options["products-source"]]);

  useEffect(() => {
    if (
      !["Shopify", "ShopifyGraphql"].includes(
        element.options["products-source"]
      )
    ) {
      Products.sortableStart(element);
    }
  }, [props.object_id]);

  const updateShopifyProductVariantsDropdown = () => {
    let tempProductVariantsDropdown = [];

    if (shopifyIntegration?.api_data["products"]) {
      shopifyIntegration?.api_data["products"]
        .filter((shopifyProduct) =>
          element.options["shopify-products-selected"]
            .split(",")
            .includes(shopifyProduct.id)
        )
        .forEach((shopifyProduct) => {
          shopifyIntegration?.api_data["product_variants"]
            .filter(
              (productVariant) =>
                productVariant.product_id == shopifyProduct.product_id
            )
            .forEach((productVariant) => {
              tempProductVariantsDropdown.push({
                value: productVariant["id"] + "",
                text: productVariant["name"],
              });
            });
        });

      setProductVariantsDropdown(tempProductVariantsDropdown);
    }

  };

  const updateShopifyGraphqlProductVariantsDropdown = (products) => {
    let tempProductVariantsDropdown = [];

    products
      .filter((shopifyAdapter) =>
        element.options["shopify-products-selected"]
          .split(",")
          .includes(shopifyAdapter.handle)
      )
      .forEach((shopifyAdapter) => {
        shopifyAdapter.variants.forEach((productVariant) => {
          tempProductVariantsDropdown.push({
            value: productVariant.id,
            text: productVariant.title.includes("Default Title") ? shopifyAdapter.title : productVariant.title,
          });
        });
      });

    setProductVariantsDropdown(tempProductVariantsDropdown);
  };

  const integrationAdded = () => {
    document.addEventListener("integrationAdded", function (event) {
      let integration = event.detail.integration;
      let newContextData = { ...contextData };
      newContextData.objects.website.integrations[integration.id] = integration;
      setContextData(newContextData);
    });

    $(document).on("shown.bs.modal", ".integration-stacked-modal", function () {
      $("#integrations, #settingsModal").modal("hide");
    });

    $(document).on(
      "hidden.bs.modal",
      ".integration-stacked-modal",
      function () {
        $("#integrations").modal("show");
      }
    );
  };

  const loadShopifyGraphqlProducts = (products) => {
    let tempProductsDropdown = [];
    let tempHandlesDropdown = [];
    let tempCollectionsDropdown = [];
    let orderedProducts = products.sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    const defaultSelect = { value: "", text: "Select a product", disabled: true }
    tempProductsDropdown.push(defaultSelect)
    tempHandlesDropdown.push(defaultSelect)

    orderedProducts.forEach((shopifyAdapter) => {
      tempProductsDropdown.push({
        value: shopifyAdapter.id,
        text: shopifyAdapter.title,
      });

      tempHandlesDropdown.push({
        value: shopifyAdapter.handle,
        text: shopifyAdapter.title,
      });
    });

    shopifyIntegration?.api_data["collections"].forEach((shopifyCollection) => {
      tempCollectionsDropdown.push({
        value: shopifyCollection["id"],
        text: shopifyCollection["name"],
      });
    });

    setProductsDropdown(tempProductsDropdown);
    setHandlesDropdown(tempHandlesDropdown);
    setCollectionsDropdown(tempCollectionsDropdown);

    if (element.options["shopify-products-selected"]) {
      updateShopifyGraphqlProductVariantsDropdown(orderedProducts);
    }
  };

  const fetchShopifyGraphql = () => {
    setShopifyGraphqlLoading(true);

    const cached_products = window.cf_shopify_products;

    if (cached_products) {
      loadShopifyGraphqlProducts(cached_products);
      setShopifyGraphqlLoading(false);
      return;
    }

    if (shopifyIntegration) {
      const parsed_store_name = (
        shopifyIntegration?.api_data?.api_url ||
        shopifyIntegration?.api_data?.website
      )
        ?.replace("http://", "")
        ?.replace("https://", "")
        ?.replace(".myshopify.com", "")
        ?.replace("/", "")
        ?.replace(" ", "");
      const shopifyClient = new ShopifyGraphqlClient({
        store_name: parsed_store_name,
        token: shopifyIntegration?.api_data["storefront-token"],
      });

      new ProductsAdapterService(shopifyClient, "ShopifyGraphql")
        .getProducts({ sort: element.options["shopify-products-sorting"] })

      setShopifyGraphqlLoading(false);
    }
  };

  useEffect(() => {
    const handleShopifyProductsChange = () => {
      if (
        shopifyIntegration &&
        element.options["products-source"] == "ShopifyGraphql" &&
        window.cf_shopify_products &&
        Object.values(window.cf_shopify_products).length > 0
      ) {
        loadShopifyGraphqlProducts(window.cf_shopify_products);
      }
    };

    document.addEventListener("cf_shopify_products_change", handleShopifyProductsChange);

    return () => {
      document.removeEventListener("cf_shopify_products_change", handleShopifyProductsChange);
    };
  }, []);

  const showFilterOperator = () => {
    return typeof element.options["shopify-products-filter"] == "string" &&
      element.options["shopify-products-filter"].length > 0 &&
      (element.options["shopify-products-filter"].indexOf(',') > -1 || element.options["shopify-products-filter"].indexOf('{') > -1) &&
      shopifyIntegration !== undefined &&
      ["ShopifyGraphql"].includes(element.options["products-source"]) &&
      element.options["shopify-products-source"] == "filter" && ["products", "product_hero"].includes(element.element_type)
  }

  const showVariantFilterOperator = () => {
    return typeof element.options["products-variant-filter"] == "string" &&
      element.options["products-variant-filter"].length > 0 &&
      (element.options["products-variant-filter"].indexOf(',') > -1 || element.options["products-variant-filter"].indexOf('{') > -1) &&
      shopifyIntegration !== undefined &&
      ["ShopifyGraphql"].includes(element.options["products-source"]) &&
      element.options["shopify-products-source"] == "filter" && ["products", "product_hero"].includes(element.element_type)
  }

  const SHOPIFY_HEADLESS_ONLY_START_DATE = new Date("2024-08-01T00:00:00Z");

  const customFields = variantCustomFieldsArray(website, variant, contextData, true);

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-source]"
        setting_type="dropdown"
        label="Products Source"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-source"]
            ? element.options["products-source"]
            : "ConvertFlow"
        }
        options={[
          { value: "ConvertFlow", text: "ConvertFlow" },
          {
            value: "Shopify",
            text: "Shopify (Legacy)",
            condition: element.element_type == "products" && new Date(website.created_at) < SHOPIFY_HEADLESS_ONLY_START_DATE,
          },
          {
            value: "ShopifyGraphql",
            text: `Shopify${shopifyIntegration?.api_data["storefront-token"] ? "" : " - Reauthentication Required"}`,
          },
        ]}
        tooltip={`The products you display can either be manually added into ConvertFlow, or use your Shopify store's product recommendation engine to populate the product feed`}
        note={
          element.options["products-source"] == "Shopify"
            ? "Products will dynamically display using Shopify recommendation engine when viewed on store"
            : ""
        }
        callback={(value) => {
          if (value == "ShopifyGraphql" && shopifyIntegration?.api_data["storefront-token"]) {
            fetchShopifyGraphql();
          }
        }}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products]"
        setting_type="html"
        condition={
          ["products", "product_hero"].includes(element.element_type) &&
          !["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          )
        }
        warning={productsList.length == 0 ? "No products added. Add below" : ""}
        style={{ padding: "0px", marginTop: "15px" }}
      >

        <div className="col-xs-12">
          <div className="mb-[5px] text-xl text-black dark:text-white">
          {element.element_type == "products" ? "Products" : "Product"}
          </div>
        </div>

        <div
          className="sortable-container col-xs-12 products-list"
          data-object_type="elements"
          data-object_id={element.id}
        >
          {Object.values(productsList)
            .filter((e) => e.toBeDeleted !== true)
            .map((product, i) => {
              return (
                <React.Fragment key={"product-settings-" + product.id}>
                  <ProductSettings
                    product={product}
                    callback={(e) => {
                      builder.addObjectToBuilder("products", e, false);
                    }}
                    {...props}
                  />
                </React.Fragment>
              );
            })}
        </div>

        <div style={{ marginLeft: "14px" }}>
          {(element.element_type == "product_hero" ||
            element.element_type == "products") &&
            element.options["products-source"] !== "Shopify" && (
              <NewProduct
                element={element}
                callback={() => updateProductsList()}
              />
            )}
        </div>
      </VariantBuilderSetting>

      <VariantBuilderSetting
        object={element}
        setting_name="[integrations-modal]"
        setting_type="html"
        object_type="elements"
        object_id={element.id}
        onload={integrationAdded}
        condition={
          shopifyIntegration == undefined &&
          element.options["products-source"] == "Shopify"
        }
      >
        <div
          className="row hard-center p-[15px] integration-first-message bg-slate-100 rounded dark:bg-slate-800"
          data-element-id={element.id}
        >
          <p className="font-bold text-xl text-black dark:text-white">
            Integrate Shopify
          </p>
          <p className="text-lg text-black dark:text-white">
            Recommend your Shopify products
          </p>
          <button
            className="text-lg px-3 py-2 font-bold text-white bg-black dark:bg-black border-transparent rounded-lg"
            data-toggle="modal"
            data-target="#integrations"
          >
            + Integration
          </button>
        </div>
      </VariantBuilderSetting>

      <VariantBuilderSetting
        object={element}
        setting_name="[integrations-modal]"
        setting_type="html"
        object_type="elements"
        object_id={element.id}
        onload={integrationAdded}
        condition={
          (shopifyIntegration == undefined || shopifyIntegration.api_data["storefront-token"] == undefined) &&
          element.options["products-source"] == "ShopifyGraphql"
        }
      >
        <div
          className="row hard-center p-[15px] integration-first-message bg-slate-100 rounded dark:bg-slate-800"
          data-element-id={element.id}
        >
          <p className="font-bold text-xl text-black dark:text-white">
            Activate Shopify headless source
          </p>
          <p className="text-lg text-black dark:text-white">
            { shopifyIntegration == undefined && 'Integrate Shopify to display your products.'}
            { shopifyIntegration && shopifyIntegration.api_data["storefront-token"] == undefined && 'The Shopify headless source requires new permissions.'}
          </p>
          <button
            className="text-lg px-3 py-2 font-bold text-white bg-black dark:bg-black border-transparent rounded-lg"
            data-toggle="modal"
            data-target="#integrations"
          >
            Connect →
          </button>
        </div>
      </VariantBuilderSetting>

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-source]"
        setting_type="dropdown"
        label="Default Products Filter"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["shopify-products-source"]
            ? element.options["shopify-products-source"]
            : "cart"
        }
        options={[
          { value: "cart", text: "Products Added To Cart", condition: element.element_type == "products" },
          { value: "collection", text: "Products In Collection", condition: element.element_type == "products" },
          { value: "filter", text: "Products Filter" },
          { value: "selected", text: "Select Products" },
          { value: "current", text: "Current Product Page" },
          { value: "filter_quiz_answers", text: "Quiz Auto Recommendation", condition: element.options["products-source"] == "ShopifyGraphql" },
          { value: "related", text: "Related To A Product", condition: element.element_type == "products" },
        ]}
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) && ["products", "product_hero"].includes(element.element_type)
        }
        loading={
          shopifyIntegration !== undefined &&
          element.options["products-source"] == "ShopifyGraphql" &&
          shopifyGraphqlLoading
        }
        feature_gate_type="Upsells & Cross-sells"
      />

      <VariantBuilderSetting
        object={element}
        object_id={element.id}
        setting_name="[options][products-filter-quiz-answers-operator]"
        setting_type="options_buttons"
        object_type="elements"
        label="Product Name Or Tags Must Match"
        options={
          [

            { value: "OR", title: "ANY Answer", height: 40 },
            { value: "AND", title: "ALL Answers", height: 40 },
          ]
        }
        condition={
          shopifyIntegration !== undefined &&
          element.options["products-source"] == "ShopifyGraphql" &&
          element.options["shopify-products-source"] == "filter_quiz_answers"
        }
        value={element.options["products-filter-quiz-answers-operator"]}
        placeholder={"OR"}
      />

      <VariantBuilderSetting
        object={element}
        object_id={element.id}
        label="Exclude Questions From Auto Filter"
        setting_name="[options][products-filter-quiz-answers-excluded-fields]"
        setting_type="select"
        multi_select={true}
        object_type="elements"
        options={customFields}
        condition={
          shopifyIntegration !== undefined &&
          element.options["products-source"] == "ShopifyGraphql" &&
          element.options["shopify-products-source"] == "filter_quiz_answers"
        }
        value={element.options["products-filter-quiz-answers-excluded-fields"]}
        placeholder={"Choose questions to exclude"}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-related-type]"
        setting_type="dropdown"
        label="Show Products Related To"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["shopify-products-related-type"]
            ? element.options["shopify-products-related-type"]
            : "current"
        }
        options={[
          { value: "manual", text: "Manual Product Selection" },
          { value: "current", text: "Current Product Page" },
          { value: "cart", text: "First Product In Cart" },
          { value: "order", text: "First Product In Last Order" },
        ]}
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) &&
          element.options["shopify-products-source"] == "related" && element.element_type == "products"
        }
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-collection]"
        setting_type="select"
        label={"Collection"}
        object_type="elements"
        object_id={element.id}
        options={collectionsDropdown}
        value={
          element.options["shopify-products-collection"]
            ? element.options["shopify-products-collection"]
            : ""
        }
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) &&
          element.options["shopify-products-source"] == "collection" && element.element_type == "products"
        }
        multi_select={true}
        warning={
          !element.options["shopify-products-collection"]
            ? "A selected collection is required"
            : undefined
        }
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-related-default]"
        setting_type="select"
        label={
          ["cart", "current", "order", undefined].includes(
            element.options["shopify-products-related-type"]
          )
            ? "Fallback Product For Recommendation"
            : "Show Products Related To"
        }
        object_type="elements"
        object_id={element.id}
        options={productsDropdown}
        value={
          element.options["shopify-products-related-default"]
            ? element.options["shopify-products-related-default"]
            : ""
        }
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) &&
          element.options["shopify-products-source"] == "related" && element.element_type == "products"
        }
        warning={
          !element.options["shopify-products-related-default"] &&
          element.options["shopify-products-related-type"] == "manual"
            ? "A product selection is required"
            : undefined
        }
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-related-intent]"
        setting_type="dropdown"
        label="Product Relation Intent"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["shopify-products-related-intent"]
            ? element.options["shopify-products-related-intent"]
            : "related"
        }
        options={[
          { value: "related", text: "Related" },
          {
            value: "complementary",
            text: "Complementary (Search & Discovery app)",
          },
        ]}
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) &&
          element.options["shopify-products-source"] == "related"
        }
        tooltip="Related: Products that are similar to the product being viewed. <br><br>Complementary: Products that are often purchased together with the product used for the recommendation, which must be configured using the official Shopify Search & Discovery app."
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-filter]"
        setting_type="text"
        label="Filter Query"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["shopify-products-filter"]
            ? element.options["shopify-products-filter"]
            : ""
        }
        placeholder={element.options["products-source"] == "ShopifyGraphql" ? "Comma separated keywords" : "Name or tag"}
        note={element.options["products-source"] == "ShopifyGraphql" ? "Tip: Filter by answers using merge tags like {pet_type}" : ""}
        note_full_width={true}
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) &&
          element.options["shopify-products-source"] == "filter" && ["products", "product_hero"].includes(element.element_type)
        }
        warning={
          !element.options["shopify-products-filter"]
            ? "A products filter is required"
            : undefined
        }
        col={showFilterOperator() ? "8" : "12"}
      />

      <VariantBuilderSetting
        object={element}
        object_id={element.id}
        setting_name="[options][products-filter-operator]"
        setting_type="options_buttons"
        label="Operator"
        object_type="elements"
        options={
          [
            { value: "OR", title: "OR" },
            { value: "AND", title: "AND" },
          ]
        }
        condition={showFilterOperator()}
        value={element.options["products-filter-operator"]}
        placeholder={"OR"}
        col="4"
        classNames="no-padding-left"
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-variant-filter]"
        setting_type="text"
        label="Variant Filter Query"
        object_type="elements"
        object_id={element.id}
        value={element.options["products-variant-filter"]}
        placeholder="Comma separated keywords"
        note="Tip: Filter by answers using merge tags like {pet_type}"
        note_full_width={true}
        condition={
          shopifyIntegration !== undefined &&
          ["ShopifyGraphql"].includes(element.options["products-source"]) &&
          element.options["shopify-products-source"] == "filter" && ["products", "product_hero"].includes(element.element_type)
        }
        col={showVariantFilterOperator() ? "8" : "12"}
      />

      <VariantBuilderSetting
        object={element}
        object_id={element.id}
        setting_name="[options][products-variant-filter-operator]"
        setting_type="options_buttons"
        label="Operator"
        object_type="elements"
        options={
          [
            { value: "OR", title: "OR" },
            { value: "AND", title: "AND" },
          ]
        }
        condition={showVariantFilterOperator()}
        value={element.options["products-variant-filter-operator"]}
        placeholder={"OR"}
        col="4"
        classNames="no-padding-left"
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-current-fallback]"
        setting_type="select"
        label={"Fallback Product"}
        object_type="elements"
        object_id={element.id}
        options={handlesDropdown}
        value={
          element.options["shopify-products-current-fallback"]
            ? element.options["shopify-products-current-fallback"]
            : ""
        }
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(element.options["products-source"])
          && (
            ["current", "filter_quiz_answers"].includes(element.options["shopify-products-source"])
              && ["products", "product_hero"].includes(element.element_type)
              || ["filter"].includes(element.options["shopify-products-source"])
              && element.element_type == "product_hero"
          )
        }
        warning={
          !element.options["shopify-products-current-fallback"]
            ? "A fallback product is required"
            : undefined
        }
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-sorting]"
        setting_type="dropdown"
        label={"Product Sorting"}
        object_type="elements"
        object_id={element.id}
        options={[
          { value: "", text: "Title" },
          { value: "BEST_SELLING", text: "Best Selling" },
          { value: "CREATED_AT", text: "Created At" },
          { value: "UPDATED_AT", text: "Updated At" },
          { value: "PRICE_ASC", text: "Cheapest" },
          { value: "PRICE_DESC", text: "Most Expensive" },
          { value: "PRODUCT_TYPE", text: "Product Type" },
          { value: "VENDOR", text: "Vendor" },
        ]}
        value={element.options["shopify-products-sorting"]}
        condition={
          shopifyIntegration !== undefined &&
          ["ShopifyGraphql"].includes(element.options["products-source"]) &&
          ["collection", "related", "filter", "selected"].includes(
            element.options["shopify-products-source"]
          ) && element.element_type == "products"
        }
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-unavailable]"
        setting_type="checkbox"
        label="Show Unavailable Products"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["shopify-products-unavailable"]
            ? element.options["shopify-products-unavailable"]
            : "false"
        }
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) &&
          ["collection"].includes(element.options["shopify-products-source"]) && element.element_type == "products"
        }
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-selected]"
        setting_type="select"
        label="Products To Display"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["shopify-products-selected"]
            ? element.options["shopify-products-selected"]
            : ""
        }
        options={handlesDropdown}
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) &&
          element.options["shopify-products-source"] == "selected"
        }
        multi_select={element.element_type == "products"}
        warning={
          !element.options["shopify-products-selected"] && shopifyGraphqlLoading == false
            ? "Product selection is required"
            : undefined
        }
        callback={() => {
          if (element.options["products-source"] == "ShopifyGraphql") {
            updateShopifyGraphqlProductVariantsDropdown(
              window.cf_shopify_products
            );
          } else {
            updateShopifyProductVariantsDropdown();
          }
        }}
        loading={
          shopifyIntegration !== undefined &&
          element.options["products-source"] == "ShopifyGraphql" &&
          shopifyGraphqlLoading
        }
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][shopify-products-selected-variants]"
        setting_type="select"
        label="Limit To Product Variants"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["shopify-products-selected-variants"]
            ? element.options["shopify-products-selected-variants"]
            : ""
        }
        options={productVariantsDropdown}
        condition={
          shopifyIntegration !== undefined &&
          ["Shopify", "ShopifyGraphql"].includes(
            element.options["products-source"]
          ) &&
          element.options["shopify-products-source"] == "selected"
        }
        multi_select={true}
      />

      <div className="col-xs-12">
        <div className="w-full my-5 relative text-black dark:text-white">
          <span className="text-xl">Conditional Recommendations:</span>

          <Tooltip
            tooltip={`Use conditions to override your default product recommendation with another product filter. The first recommendation that meets the conditions will be used.`}
          />
        </div>

        {product_recommendations.map((product_recommendation) => {
          return (
            <ProductRecommendationSettings
              key={"product-recommendation-" + product_recommendation.id}
              step={step}
              section={section}
              element={element}
              product_recommendation={product_recommendation}
            />
          );
        })}
      </div>
      <NewProductRecommendation element={element} />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-empty-message]"
        setting_type="editor"
        label="Message If No Products Found"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-empty-message"]
            ? element.options["products-empty-message"]
            : "<p>Sorry, there are no products to display</p>"
        }
        condition={["Shopify", "ShopifyGraphql"].includes(
          element.options["products-source"]
        ) && element.element_type == "products"}
        preview_selector=".cf-products-empty-message"
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-limit]"
        setting_type="range"
        label="Maximum Number Of Products"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-limit"]
            ? element.options["products-limit"]
            : undefined
        }
        placeholder="3"
        allow_empty={true}
        min={1}
        max={12}
        condition={
          element.options["products-source"]?.toLowerCase() !== "convertflow" &&
          element.options["shopify-products-source"] !== "selected" &&
          element.element_type == "products"
        }
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-layout]"
        setting_type="layout"
        label="Products Layout"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-layout"]
            ? element.options["products-layout"]
            : "grid"
        }
        valueMappings={{
          responsive: "grid",
          stacked: "list",
        }}
        condition={element.element_type == "products" && element.element_type == "products"}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-overflow-scroll]"
        setting_type="checkbox"
        label="Side Scrolling"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-overflow-scroll"] == "true"
            ? "true"
            : "false"
        }
        condition={element.options["products-layout"] !== "list" && element.element_type == "products"}
        device="desktop"
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-overflow-scroll-mobile]"
        setting_type="checkbox"
        label="Side Scrolling"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-overflow-scroll-mobile"] == "true" || element.options["products-overflow-scroll"] == "true"
            ? "true"
            : "false"
        }
        condition={element.options["products-layout"] !== "list" && element.element_type == "products"}
        device="mobile"
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-limit-row]"
        setting_type="range"
        label="Products Per Row"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-limit-row"]
            ? element.options["products-limit-row"]
            : "3"
        }
        min={1}
        max={4}
        condition={element.options["products-layout"] !== "list" && element.element_type == "products" && element.options["products-overflow-scroll"] !== "true"}
        device="desktop"
        show_reset={true}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-limit-row-mobile]"
        setting_type="range"
        label="Products Per Row"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-limit-row-mobile"]
            ? element.options["products-limit-row-mobile"]
            : "1"
        }
        min={1}
        max={4}
        condition={element.options["products-layout"] !== "list" && element.element_type == "products" && element.options["products-overflow-scroll"] !== "true" && element.options["products-overflow-scroll-mobile"] !== "true"}
        device="mobile"
        show_reset={true}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-overflow-item-width]"
        setting_type="range"
        label="Product SKU Width"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-overflow-item-width"]
            ? element.options["products-overflow-item-width"]
            : "300"
        }
        min={50}
        max={1440}
        condition={element.options["products-layout"] !== "list" && element.element_type == "products" && element.options["products-overflow-scroll"] == "true"}
        device="desktop"
        show_reset={true}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][products-overflow-item-width-mobile]"
        setting_type="range"
        label="Product SKU Width"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["products-overflow-item-width-mobile"]
            ? element.options["products-overflow-item-width-mobile"]
            : element.options["products-overflow-item-width"]
            ? element.options["products-overflow-item-width"]
            : "300"
        }
        min={50}
        max={1440}
        condition={element.options["products-layout"] !== "list" && element.element_type == "products" && (element.options["products-overflow-scroll-mobile"] == "true" || (element.options["products-overflow-scroll-mobile"] !== "false" && element.options["products-overflow-scroll"] == "true"))}
        device="mobile"
        show_reset={true}
      />
    </React.Fragment>
  );
};

export default ElementSettingsProductFeed;
