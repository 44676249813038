import React, { useEffect } from "react";
import PropTypes from "prop-types";

const ElementVideo = (props) => {
  let element = props.element;
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element
  );
  let videoURL = element.options["video-embed-url"]
    ? element.options["video-embed-url"]
    : "";

  if (videoURL && videoURL.indexOf("?") == -1) {
    videoURL += "?";
  }
  let videoURLFormatted = videoURL;
  let videoOptions = "fullscreen;";

  if (videoURL.indexOf("youtube") > -1) {
    let videoId;

    if (videoURL.indexOf("watch?v=") > -1) {
      videoId = videoURL.split("watch?v=")[1];
    } else {
      videoId = videoURL.split("/").at(-1)
    }

    videoURLFormatted = `https://www.youtube-nocookie.com/embed/${videoId}?`;

    if (element.options["video-embed-controls"] == "false") {
      videoURLFormatted += "&controls=0";
    } else {
      videoURLFormatted += "&controls=1";
    }

    if (element.options["video-embed-related"] == "true") {
      videoURLFormatted += "&rel=1";
    } else {
      videoURLFormatted += "&rel=0";
    }

    if (element.options["video-embed-loop"] == "true") {
      videoURLFormatted += `&playlist=${videoId}`;
    }
  }

  if (videoURL.indexOf("wistia") > -1 && videoURL.indexOf("/medias/") > -1) {
    const videoId = videoURL.split("/medias/")[1];
    videoURLFormatted = `https://fast.wistia.net/embed/iframe/${videoId}?videoFoam=true`;
  }

  if (videoURL.indexOf("vimeo") > -1 && videoURL.indexOf("vimeo.com/") > -1) {
    const videoId = videoURL.split("vimeo.com/")[1];
    videoURLFormatted = `https://player.vimeo.com/video/${videoId}?`;
  }

  if (videoURLFormatted) {
    if (element.options["video-embed-autoplay"] == "true") {
      videoURLFormatted += "&autoplay=1";
      videoOptions += " autoplay;";
    } else {
      videoURLFormatted += "&autoplay=0";
    }

    if (element.options["video-embed-loop"] == "true") {
      videoURLFormatted += "&loop=1";
    } else {
      videoURLFormatted += "&loop=0";
    }
  }
  return (
    <React.Fragment>
      <div className={classes}>
        {props.builder !== true && videoURLFormatted && (
          <div className="cf-video">
            <iframe
              allowFullScreen="1"
              data-src={videoURLFormatted}
              allow={videoOptions}
              loading="lazy"
              scrolling="no"
            ></iframe>
          </div>
        )}
        {props.builder == true && (
          <div className="cf-html-placeholder element-content">
            <strong>
              Video: <em>{videoURL ? videoURL : "Video Embed"}</em>
            </strong>
            <br></br>
            Click to edit
          </div>
        )}
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-video-outer ";

    let alignmentClass = "cf-outer-center";

    if (options["cf-alignment"]) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        alignmentClass = "cf-outer-left";
      } else if (options["cf-alignment"].indexOf("center") > -1) {
        alignmentClass = "cf-outer-center";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        alignmentClass = "cf-outer-right";
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element) => {
    let options = { ...element.options };

    let boxShadow;

    if (options["box-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["box-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["box-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["box-shadow-horizontal-distance"]
          ? `${element.options["box-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-vertical-distance"]
          ? `${element.options["box-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-blur-radius"]
          ? `${element.options["box-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-spread-radius"]
          ? `${element.options["box-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-color"]
          ? element.options["box-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    // prettier-ignore
    const desktopStyles =
`#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-video-outer {
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
  ${options["video-embed-width"] == 'custom' && options["video-embed-width-value"] ? 'max-width: ' + options["video-embed-width-value"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-video {
  max-width: 100%;
  min-height: 20px;
  ${options["video-embed-height-value"] ? `height: ${options["video-embed-height-value"]}px !important;` : 'padding-top: 56.25%;'}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-html-placeholder {
  width: 100%;
  ${options["video-embed-height-value"] ? `height: ${options["video-embed-height-value"]}px !important;` : ''}
}
`;

    // prettier-ignore
    const mobileStyles =
`/* mobile styles */
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] {
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-video,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-html-placeholder {
  ${options["video-embed-height-value-mobile"] ? `height: ${options["video-embed-height-value-mobile"]}px !important;` : ''}
}
`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementVideo;
