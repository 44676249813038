import React, { useEffect } from "react";
import Section from "../sections/Section";
import arePropsEqual from "../../utils/arePropsEqual";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);

const ElementCarousel = (props) => {
  let element = props.element;
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    props.variant
  );

  let sorted_carousel_items = Object.values(element.content_toggle_items)
    .filter(
      (tab_item) =>
        tab_item.element_id == element.id && tab_item.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  useEffect(() => {
    if (props.builder == true) {
      if (getCurrentPosition() == 0) {
        updateIndicatorPositionTo(1);
      }
    }
  }, [sorted_carousel_items]);

  let getCurrentPosition = () => {
    let carousel_items = document.querySelectorAll(
      `#builder .cf-element[data-element-id="${element.id}"] .cf-carousel-item`
    );

    return (
      [...carousel_items].findIndex((item) =>
        item.classList.contains("active")
      ) + 1
    );
  };

  const updateIndicatorPositionTo = (target_position) => {
    $(`div[data-element-id="${element.id}"]`).attr(
      "data-slide-page",
      target_position
    );

    if (element.options["carousel-hide-indicator-controls"] !== "true") {
      if (target_position > sorted_carousel_items.length) {
        target_position = 1;
      } else if (target_position == 0) {
        target_position = sorted_carousel_items.length;
      }

      let indicator = `#builder .cf-element[data-element-id="${element.id}"] .cf-carousel-indicator`;
      $(indicator).removeClass("active");
      $(`${indicator}[data-position="${target_position}"]`).addClass("active");
    }
  };

  const carouselIndicators = () => {
    return (
      <React.Fragment>
        <div className="cf-carousel-indicators">
          {props.element.options["carousel-hide-indicator-controls"] !==
            "true" &&
            sorted_carousel_items.map((carousel) => {
              if (carousel.position <= sorted_carousel_items.length) {
                return (
                  <div
                    key={`cf-carousel-${element.id}-${carousel.position}`}
                    className={`cf-indicator-go-to cf-carousel-indicator`}
                    data-position={carousel.position}
                  > </div>
                );
              }
            })}
        </div>
      </React.Fragment>
    );
  };

  const carouselSlides = () => {
    return (
      <React.Fragment>
        <div className="cf-carousel">
          {/* START DYNAMIC ITEMS LOADING */}
          {sorted_carousel_items.map((carousel) => {
            let carousel_item_section = Object.values(element.sections).filter(
              (section) =>
                section.content_toggle_item_id == carousel.id &&
                section.step_id == props.section.step_id
            )[0];

            return (
              <div
                key={`cf-carousel-item-${carousel.id}`}
                className={"cf-carousel-item"}
                data-toggle-item-id={carousel.id}
              >
                {carousel_item_section ? (
                  <Section
                    key={`cf-carousel-section-item-${carousel.id}`}
                    website={props.website}
                    cta={props.cta}
                    variant={props.variant}
                    builder={props.builder}
                    preview={props.preview}
                    step={props.step}
                    section={carousel_item_section}
                    device={props.device}
                  ></Section>
                ) : (
                  ""
                )}
              </div>
            );
          })}
          {/* END DYNAMIC ITEMS LOADING */}
        </div>
      </React.Fragment>
    );
  };

  const carouselLeftArrow = (location) => {
    return (
      <React.Fragment>
        {props.element.options["carousel-hide-arrow-controls"] !== "true" &&
        carouselControlsStyle == location ? (
          <div
            className={`cf-arrow-control cf-arrow-left ${
              props.builder == true ? "editing" : ""
            }`}
          >
            <FontAwesomeIcon icon="angle-left" />
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  const carouselRightArrow = (location) => {
    return (
      <React.Fragment>
        {props.element.options["carousel-hide-arrow-controls"] !== "true" &&
        carouselControlsStyle == location ? (
          <div
            className={`cf-arrow-control cf-arrow-right ${
              props.builder == true ? "editing" : ""
            }`}
          >
            <FontAwesomeIcon icon="angle-right" />
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    const element_div = `#builder .cf-carousel-element[data-element-id="${element.id}"]`;

    $(`${element_div} .cf-carousel-wrapper`).each(function (i, wrapper) {
      const EL_slider = $(wrapper).find(".cf-carousel");
      const ELS_items = $(wrapper).find(".cf-carousel-item");
      const sub = +wrapper.dataset.items ?? 1;
      const tot = Math.ceil(ELS_items.length / sub);
      let c = 0;

      const ratioPerSlide = () => parseFloat(((100 / sub) * 0.01).toFixed(5));

      const carouselPagesMatrix = (targetPosition) => {
        let positionPageMatrix = {};
        let slidesPerView = sub;
        let totalPages = tot;
        let slidePositionCounter = 1;
        let ratioCounter = 0;

        Array.from({ length: totalPages }).forEach((_x, page_number) => {
          Array.from({ length: slidesPerView }).forEach((_x) => {
            positionPageMatrix[slidePositionCounter] = ratioCounter;
            slidePositionCounter += 1;
            ratioCounter += ratioPerSlide();
          });
        });

        if (targetPosition) {
          return positionPageMatrix[targetPosition];
        } else {
          return positionPageMatrix;
        }
      };

      const totalNonEmptySlides = () => {
        return (sorted_carousel_items.length - sub) + 1
      }

      const selectElement = () => {
        dispatchCustomEvent("selectObject", {
          object_type: "elements",
          object_id: element.id
        });
      }

      const anim = () => EL_slider.css("transform", `translateX(-${c * 100}%)`);

      const prev = () => {
        let currentPage = parseInt($(`div[data-element-id="${element.id}"]`).attr('data-slide-page'));

        if (currentPage == 1) {
          currentPage = totalNonEmptySlides();
        } else {
          currentPage -= 1;
        }

        goToPage(currentPage);
      };

      const next = () => {
        let currentPage = parseInt($(`div[data-element-id="${element.id}"]`).attr('data-slide-page'));

        if (currentPage == totalNonEmptySlides()) {
          currentPage = 1;
        } else {
          currentPage += 1;
        }

        goToPage(currentPage);
      };

      const goToPage = (target_position) => {
        updateIndicatorPositionTo(target_position);
        return (c = carouselPagesMatrix(target_position)), anim();
      };

      // Fix position when changing number of slides per view
      goToPage(getCurrentPosition() || 1);

      $(`${element_div} .cf-indicator-go-to`).each(function (index, indicator) {
        if (index >= totalNonEmptySlides()) {
          $(indicator).hide();
        } else {
          $(indicator).show();
          $(indicator).off("click").on("click", (x) => goToPage(x.target.dataset["position"]));
        }
      });
      $(`${element_div} .cf-arrow-left`).off("click").on("click", prev);
      $(`${element_div} .cf-arrow-right`).off("click").on("click", next);
    });
  }, [
    sorted_carousel_items,
    element.options["carousel-slides-per-view"],
    element.options["carousel-slides-per-view-mobile"],
  ]);

  let carouselControlsStyle =
    element.options["carousel-controls-style"] ||
    props.variant.options["carousel-controls-style"] ||
    "inside";

  return (
    <React.Fragment>
      <div className={classes} data-element-id={element.id} data-slide-page="1">
        <div
          className="cf-carousel-wrapper"
          data-items={
            (props.builder == true
              ? props.device == "desktop"
                ? element.options["carousel-slides-per-view"]
                : element.options["carousel-slides-per-view-mobile"]
              : 1) || 1
          }
        >
          {carouselLeftArrow("outside")}

          <div className="cf-carousel-slide-wrapper">
            {carouselLeftArrow("inside")}

            <div
              className="cf-carousel-slide-inside-wrapper"
              style={{ overflow: "hidden", flex: "1" }}
            >
              {carouselSlides()}
            </div>

            {carouselRightArrow("inside")}
          </div>

          {carouselRightArrow("outside")}
        </div>

        {carouselIndicators()}

        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-carousel-element ";
    let alignmentClass = "cf-outer-center";

    if (device == "mobile") {
      if (options["cf-alignment-mobile"]) {
        if (options["cf-alignment-mobile"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment-mobile"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment-mobile"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    } else {
      if (options["cf-alignment"]) {
        if (options["cf-alignment"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element, variant) => {
    let options = { ...element.options };

    let carouselControlsStyle =
      options["carousel-controls-style"] ||
      variant.options["carousel-controls-style"] ||
      "inside";

    let maxWidthElement = "";
    if (
      options["carousel-max-width-style"] == "custom" &&
      options["carousel-max-width-value"]
    ) {
      if (carouselControlsStyle == "outside") {
        maxWidthElement = `${
          parseInt(options["carousel-max-width-value"]) + 90 // include arrow controls safety space
        }px`;
      } else {
        maxWidthElement = `${options["carousel-max-width-value"]}px`;
      }
    } else if (options["carousel-max-width-style"] == "full") {
      maxWidthElement = "100%";
    }

    let maxWidthSlideWrapper = "";
    if (
      options["carousel-max-width-style"] == "custom" &&
      options["carousel-max-width-value"]
    ) {
      maxWidthSlideWrapper = `${options["carousel-max-width-value"]}px`;
    } else if (options["carousel-max-width-style"] == "full") {
      maxWidthSlideWrapper = "100%";
    }

    let carouselHeight;
    if (
      options["carousel-height-style"] == "custom" &&
      options["carousel-height-value"]
    ) {
      carouselHeight = `${options["carousel-height-value"]}px !important;`;
    }

    let carouselBackgroundColor =
      options["carousel-background-color"] || undefined;

    let carouselArrowControlsColor =
      options["carousel-arrow-controls-color"] || undefined;

    let carouselIndicatorControlsColor =
      options["carousel-indicator-controls-color"] || undefined;

    let carouselIndicatorSize = options["carousel-indicator-size"] || undefined;

    let carouselArrowSize =
      options["carousel-arrow-size"] || variant.options["carousel-arrow-size"];

    let carouselIndicatorBorderRadius = "";
    if (options["carousel-indicator-border-radius"] == "cf-not-rounded") {
      carouselIndicatorBorderRadius = "0px";
    }
    if (options["carousel-indicator-border-radius"] == "cf-slightly-rounded") {
      carouselIndicatorBorderRadius = "4px";
    }
    if (options["carousel-indicator-border-radius"] == "cf-fully-rounded") {
      carouselIndicatorBorderRadius = "100px";
    }
    if (options["carousel-indicator-border-radius"] == "custom") {
      carouselIndicatorBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          carouselIndicatorBorderRadius += `${
            _.isNil(options["carousel-indicator-border-radius-" + side]) ==
            false
              ? options["carousel-indicator-border-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    // prettier-ignore
    let desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element {
        ${maxWidthElement ? `max-width: ${maxWidthElement};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-item {
        overflow-x: hidden;
        ${element.options["carousel-slides-per-view"] ? `flex: 1 0 ${100 / parseInt(element.options["carousel-slides-per-view"])}% !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-item {
        ${carouselHeight ? `height: ${carouselHeight};` : ''}
        ${carouselBackgroundColor ? `background: ${carouselBackgroundColor} !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-slide-wrapper {
        ${carouselBackgroundColor ? `background: ${carouselBackgroundColor};` : ''}
        ${maxWidthSlideWrapper ? `max-width: ${maxWidthSlideWrapper};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-slide-inside-wrapper {
        ${carouselControlsStyle == "inside" ? carouselArrowSize > 22 ? `margin: 0px -${((carouselArrowSize - 22) + 47)}px;` : "margin: 0px -47px;" : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-arrow-control svg {
        ${carouselArrowSize ? `width: ${parseInt(carouselArrowSize) + 8}px !important;` : 'width: 20px;'}
        ${carouselArrowSize ? `height: ${parseInt(carouselArrowSize) + 8}px !important;` : 'height: 20px;'}
        border: 1px solid ${carouselArrowControlsColor ? carouselArrowControlsColor : ''} !important;
        ${carouselArrowControlsColor ? `color: ${carouselArrowControlsColor} !important;` : ''}
        ${carouselArrowControlsColor ? `background-color: ${carouselArrowControlsColor}1A !important;` : ''}
        padding: 5px !important;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-arrow-control .svg-inline--fa {
        ${carouselArrowSize ? `font-size: ${carouselArrowSize}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-indicators {
        ${maxWidthSlideWrapper && carouselControlsStyle == "inside" ? `max-width: ${maxWidthSlideWrapper};` : ''}
        position: relative;
        z-index: 11;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-indicator {
        ${carouselIndicatorSize ? `width: ${carouselIndicatorSize}px !important;` : ''}
        ${carouselIndicatorSize ? `height: ${carouselIndicatorSize}px !important;` : ''}
        ${carouselIndicatorControlsColor ? `background-color: ${carouselIndicatorControlsColor} !important;` : ''}
        ${carouselIndicatorBorderRadius ? `border-radius: ${carouselIndicatorBorderRadius} !important;` : ''}
      }
      `;

    // INDIVIDUAL SLIDES STYLES
    // prettier-ignore
    Object.values(element.content_toggle_items)
      .filter(
        (tab_item) =>
          tab_item.element_id == element.id && tab_item.toBeDeleted !== true
      )
      .sort((a, b) => a.position - b.position).forEach((carousel) => {
        let carouselIndicatorBackgroundImage = "";
        if (
          carousel.options &&
          carousel.options["indicator-background-image"]
        ) {
          carouselIndicatorBackgroundImage =
            carousel.options["indicator-background-image"];
        }

        desktopStyles += `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-indicator[data-position="${carousel.position}"] {
          ${carouselIndicatorBackgroundImage ? `background-image: url("${carouselIndicatorBackgroundImage}") !important; background-size: cover;` : ""}
        }

        #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-indicator[data-position="${carousel.position}"].active {
          ${options["carousel-indicator-active-border-style"] ? 'border-style: ' + options["carousel-indicator-active-border-style"] + ' !important;' : ''}
          ${options["carousel-indicator-active-border-style"] !== "" && _.isNil(options["carousel-indicator-active-border-width"]) == false ? 'border-width: ' + options["carousel-indicator-active-border-width"] + 'px !important;' : ''}
          ${options["carousel-indicator-active-border-style"] !== "" && _.isNil(options["carousel-indicator-active-border-width-left"]) == false ? 'border-left-width: ' + options["carousel-indicator-active-border-width-left"] + 'px !important;' : _.isNil(options["carousel-indicator-active-border-width"]) == false ? 'border-left-width: ' + options["carousel-indicator-active-border-width"] + 'px !important;' : ''}
          ${options["carousel-indicator-active-border-style"] !== "" && _.isNil(options["carousel-indicator-active-border-width-right"]) == false ? 'border-right-width: ' + options["carousel-indicator-active-border-width-right"] + 'px !important;' : _.isNil(options["carousel-indicator-active-border-width"]) == false ? 'border-right-width: ' + options["carousel-indicator-active-border-width"] + 'px !important;' : ''}
          ${options["carousel-indicator-active-border-style"] !== "" && _.isNil(options["carousel-indicator-active-border-width-top"]) == false ? 'border-top-width: ' + options["carousel-indicator-active-border-width-top"] + 'px !important;' : _.isNil(options["carousel-indicator-active-border-width"]) == false ? 'border-top-width: ' + options["carousel-indicator-active-border-width"] + 'px !important;' : ''}
          ${options["carousel-indicator-active-border-style"] !== "" && _.isNil(options["carousel-indicator-active-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["carousel-indicator-active-border-width-bottom"] + 'px !important;' : _.isNil(options["carousel-indicator-active-border-width"]) == false ? 'border-bottom-width: ' + options["carousel-indicator-active-border-width"] + 'px !important;' : ''}
          ${options["carousel-indicator-active-border-style"] !== "" && options["carousel-indicator-active-border-color"] ? 'border-color: ' + options["carousel-indicator-active-border-color"] + ' !important;' : options["carousel-indicator-active-color"] ? 'border-color: ' + options["carousel-indicator-active-color"] + ' !important;' : ''}
        }
        `;
      })

    let maxWidthElementMobile = "";
    if (
      options["carousel-max-width-style-mobile"] == "custom" &&
      options["carousel-max-width-value-mobile"]
    ) {
      if (carouselControlsStyle == "outside") {
        maxWidthElementMobile = `${
          parseInt(options["carousel-max-width-value-mobile"]) + 90 // include arrow controls safety space
        }px`;
      } else {
        maxWidthElementMobile = `${options["carousel-max-width-value-mobile"]}px`;
      }
    } else if (options["carousel-max-width-style"] == "full") {
      maxWidthElementMobile = "100%";
    }

    let maxWidthSlideWrapperMobile = "";
    if (carouselControlsStyle == "outside" && element.options["carousel-hide-arrow-controls"] !== "true") {
      maxWidthSlideWrapperMobile = "75%";
    } else {
      maxWidthSlideWrapperMobile = "100%";
    }

    let carouselHeightMobile;
    if (
      options["carousel-height-style-mobile"] == "custom" &&
      options["carousel-height-value-mobile"]
    ) {
      carouselHeightMobile = `${options["carousel-height-value-mobile"]}px !important;`;
    }

    let carouselIndicatorSizeMobile =
      options["carousel-indicator-size-mobile"] || undefined;

    let carouselArrowSizeMobile =
      options["carousel-arrow-size-mobile"] ||
      variant.options["carousel-arrow-size-mobile"];

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element {
        ${maxWidthElementMobile ? `max-width: ${maxWidthElementMobile};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-item {
        ${element.options["carousel-slides-per-view-mobile"] ? `flex: 1 0 ${100 / parseInt(element.options["carousel-slides-per-view-mobile"])}% !important;` : 'flex: 1 0 100% !important;'}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-item {
        ${carouselHeightMobile ? `height: ${carouselHeightMobile}; min-height: 0px;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-slide-wrapper {
        ${maxWidthSlideWrapperMobile ? `max-width: ${maxWidthSlideWrapperMobile};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-slide-inside-wrapper {
        ${options["carousel-max-width-style-mobile"] == "custom" && options["carousel-max-width-value-mobile"] ? `max-width: ${options["carousel-max-width-value-mobile"]}px;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-indicator {
        ${carouselIndicatorSizeMobile ? `width: ${carouselIndicatorSizeMobile}px !important;` : ''}
        ${carouselIndicatorSizeMobile ? `height: ${carouselIndicatorSizeMobile}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-carousel-slide-inside-wrapper {
        ${carouselControlsStyle == "inside" ? carouselArrowSizeMobile > 22 ? `margin: 0px -${((carouselArrowSizeMobile - 22) + 47)}px;` : "margin: 0px -47px;" : ""}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-carousel-element .cf-arrow-control svg {
        ${carouselArrowSizeMobile ? `width: ${parseInt(carouselArrowSizeMobile) + 8}px !important;` : 'width: 20px;'}
        ${carouselArrowSizeMobile ? `height: ${parseInt(carouselArrowSizeMobile) + 8}px !important;` : 'height: 20px;'}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-arrow-control .svg-inline--fa {
        ${carouselArrowSizeMobile ? `font-size: ${carouselArrowSizeMobile}px !important;` : ''}
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default React.memo(ElementCarousel, arePropsEqual);
